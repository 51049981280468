import * as React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  AppBar, Box, Button, Card, Divider, IconButton, Modal, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { Colors } from 'common/src/constants';
import { DefaultStyleAttrs } from '../constants/styles';

import { Text } from 'common/src/components/base';

interface IProps {
  children?: React.ReactNode;
  visible?: boolean;
  title?: string;
  description?: string;
  errMsg?: string;
  primaryBtnLabel?: string;
  onPrimaryBtnPress?: () => void;
  secondaryBtnLabel?: string;
  onSecondaryBtnPress?: () => void;
  onOverlayPress?: () => void; // Will call when pressing the overlay outside the modal
  onClose?: () => void;
}
const ActionModal: React.FC<IProps> = ({
  children, visible, title: titleText, description: descriptionText, errMsg, primaryBtnLabel,
  onPrimaryBtnPress, secondaryBtnLabel, onSecondaryBtnPress, onOverlayPress, onClose,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  const titleRow = (titleText || onClose) ? (
    <Stack
      direction='row'
      display='flex' justifyContent='space-between' alignItems='center'
    >
      { onClose && <Box width={35} /> }
      <Box>
        <Text size='subtitle' variant='bold'>{titleText}</Text>
      </Box>
      {
        onClose && (
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 20 }} />
          </IconButton>
        )
      }
    </Stack>
  ) : null;
  const descriptionView = descriptionText ? (
    <Text centered color='system' size='paragraph' mt={10} >{descriptionText}</Text>
  ) : null;
  const primaryBtn = primaryBtnLabel ? (
    <Button
      onClick={onPrimaryBtnPress} variant='contained' disabled={!onPrimaryBtnPress}
      fullWidth={isSm}
      sx={{ borderRadius: 5 }}
    >
      {primaryBtnLabel}
    </Button>
  ) : null;
  const secondaryBtn = secondaryBtnLabel ? (
    <Button
      onClick={onSecondaryBtnPress} variant='text' disabled={!onSecondaryBtnPress}
      sx={{ borderRadius: 5, mx: { md: 10 } }}
      fullWidth={isSm}
    >
      <Text color='primary' fontSize={13} variant='underline'>{secondaryBtnLabel}</Text>
    </Button>
  ) : null;

  const contentView = (
    <Box mt={20}>
      <Box mx={DefaultStyleAttrs.mx}>
        {titleRow}
      </Box>
      {isSm && <Divider sx={{ mt: 10 }} />}
      <Box mx={DefaultStyleAttrs.mx}>
        {descriptionView}
        {children}
      </Box>
    </Box>
  );

  const mainView = isSm ? (
    <Box
      sx={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
        bgcolor: Colors.WHITE,
      }}
    >
      {contentView}
      <AppBar position='fixed' color='transparent' sx={{ top: 'auto', bottom: 0 }}>
        <Box py={20} mx={DefaultStyleAttrs.mx}>
          {primaryBtn}
          {secondaryBtn}
          {errMsg && (
            <Text size='note' color='error' centered mt={3}>{errMsg}</Text>
          )}
        </Box>
      </AppBar>
    </Box>
  ) : (
    <Box
      sx={{
        position: 'absolute', top: '50%', left: '15%', right: '15%',
        transform: 'translate(0%, -50%)',
      }}
    >
      <Card sx={{ background: Colors.WHITE }}>
        {contentView}
        <Divider />
        <Box py={20} mx={DefaultStyleAttrs.mx}>
          <Box display='flex' justifyContent='flex-end'>
            {secondaryBtn}
            {primaryBtn}
          </Box>
          {errMsg && (
            <Box display='flex' justifyContent='flex-end' mt={3}>
              <Text size='note' color='error'>{errMsg}</Text>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );

  return (
    <Modal open={visible === undefined || visible} onClose={onOverlayPress}>
      {mainView}
    </Modal>
  );
};

export default ActionModal;
