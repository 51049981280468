import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Stack, TextField,
} from '@mui/material';

import { Text } from 'common/src/components/base';

interface IProps {
  onApplyCouponCode: (couponCode: string) => Promise<string>; // return err_code, or empty string if success
}
const CouponCodeView: React.FC<IProps> = ({ onApplyCouponCode }) => {
  const { t } = useTranslation('wbevt.events');

  const [couponCode, setCouponCode] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');
  const [appliedCoupondCode, setAppliedCouponCode] = React.useState('');

  const applyCouponCode = React.useCallback(async () => {
    const errCode = await onApplyCouponCode(couponCode);
    if (errCode) {
      switch (errCode) {
        case 'invalid_coupon':
          setErrMsg(t('registrationConfirmation.invalidCoupon'));
          break;
        default:
          setErrMsg(t('registrationConfirmation.couponError'));
          break;
      }
      setAppliedCouponCode('');
    } else {
      setErrMsg('');
      setAppliedCouponCode(couponCode);
      setCouponCode('');
    }
  }, [couponCode, onApplyCouponCode, t]);


  return (
    <Box mt={20}>
      <Stack direction='row' alignItems='center' mb={5}>
        <TextField
          margin='dense'
          fullWidth
          variant='standard'
          label={t('registrationConfirmation.enterCoupon')}
          value={couponCode}
          onChange={(event) => {
            setCouponCode(event.target.value);
          }}
        />
        <Button
          onClick={applyCouponCode}
          variant='contained'
          disabled={!couponCode.length}
          sx={{ ml: 20, mb: -20 }}
        >
          {t('registrationConfirmation.useCoupon')}
        </Button>
      </Stack>
      {!!appliedCoupondCode && (
        <Box>
          <Text size='paragraph' color='system-light' variant='italics'>
            {t('registrationConfirmation.appliedCoupon')}「{appliedCoupondCode}」
          </Text>
        </Box>
      )}
      {!!errMsg && (
        <Box>
          <Text size='note' color='error'>{errMsg}</Text>
        </Box>
      )}
    </Box>
  );
};

export default CouponCodeView;
